import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";

const SEOHelper = ({ history }) => {
    const getCookie = (cookieName) => {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [key, value] = el.split('=');
            cookie[key.trim()] = value;
        })
        return cookie[cookieName];
    }

    useEffect(() => {
        var ssrTimeout = setTimeout(() => callCacheGenerate(), 5000);

        const unlisten = history.listen(() => {
            clearTimeout(ssrTimeout);
            ssrTimeout = setTimeout(() => callCacheGenerate(), 5000);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    function callCacheGenerate() {
        if (getCookie("googtrans") && getCookie("googtrans") !== "/pt/pt") {
            return false;
        }
        if (isAllowed()) {
            let data = new FormData();
            data.append("head", getStaticHead());
            data.append("body", getRepleaceableBody());
            data.append("url", window.location.pathname);
            axios.post(`${localStorage.getItem("apiUrl")}generate_cache`, data);
        }
    }

    function isAllowed() {
        let response = true;
        const disableCache = [
            "carrinho",
            "minha-conta",
            "pedido",
            "obrigado",
            "detalhes",
            "clientes",
            "editar-conta"
        ];

        disableCache.forEach(route => {
            if (window.location.pathname.includes(route)) {
                response = false;
            }
        });

        return response;
    }

    function getStaticHead() {
        let infos = [
            "description",
            "image",
            "keywords",
            "og:title",
            "og:description",
            "og:image",
            "og:image:width",
            "og:image:height",
            "og:url",
            "og:site_name",
            "og:type",
            "twitter:card",
            "twitter:site",
            "twitter:title",
            "twitter:description",
            "twitter:image"
        ];

        let newHTML = "";
        infos.forEach(info => { newHTML = newHTML + getMeta(info) });

        let title = document.querySelector("title")?.outerHTML;
        if (title) {
            newHTML = newHTML + title;
        }

        return newHTML;
    }

    function getMeta(i) {
        let meta = false;
        meta = document.querySelector(`meta[name="${i}"]`);
        if (meta) {
            meta = meta.outerHTML;
        } else {
            meta = document.querySelector(`meta[property="${i}"]`);
            if (meta) {
                meta = meta.outerHTML;
            } else {
                meta = `<meta name="${i}" property="${i}" content="" />`;
            }
        }

        return meta;
    }

    function getRepleaceableBody() {
        return document.querySelector("#root").outerHTML.replace('<div class="App">', '<div class="App" style="opacity: 0;">');
    }

    return (null);
}

export default withRouter(SEOHelper);