import React from 'react';

const Context = React.createContext({
    resumo: {},
    setResumo: () => { },
    modal: false,
    setModal: () => { },
    activePage: false,
    setActivePage: () => { },
    cart: [],
    setCart: () => { },
    loggedIn: false,
    setLoggedIn: () => { }
});

export default Context;