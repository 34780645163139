import "./index.scss";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import { useContext } from "react";
import Context from "../../Context";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="container">
                <div>
                    <Link to="/">
                        <img src={resumo.imagens.logo} alt="Alleanza" />
                    </Link>
                    <p dangerouslySetInnerHTML={{ __html: resumo.textos.rodape_texto }}>
                    </p>
                    <div className="social">
                        {resumo.configuracoes.instagram.length > 3 && <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer">
                            <img src={Instagram} alt="instagram" />
                        </a>
                        }
                        {resumo.configuracoes.facebook.length > 3 && <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer">
                            <img src={Facebook} alt="facebook" />
                        </a>
                        }
                    </div>
                </div>
                <div>
                    <div>
                        <p> Nosso Site</p>
                        <div>
                            <Link to="/empresa">
                                A Alleanza
                            </Link>
                            <Link to="/produtos">
                                Produtos
                            </Link>
                            <Link to="/blog">
                                Blog
                            </Link>
                            <a href="/mix-and-match" rel="noopener noreferrer">
                                Monte sua mesa virtual
                            </a>
                        </div>
                    </div>
                    <div>
                        <p>Links Úteis</p>
                        <div>
                            <Link to="/catalogos">
                                Catálogos
                            </Link>
                            {/* <Link to="/showroom">
                                Showroom
                            </Link> */}
                            <Link to="/linhas-exclusivas">
                                Linhas exclusivas
                            </Link>
                            <Link to="/loja-fisica">
                                Loja Física
                            </Link>
                        </div>
                    </div>
                    <div>
                        <p>Ajuda</p>
                        <div>
                            <Link to="/como-comprar">
                                Como comprar
                            </Link>
                            <Link to="/politica-de-privacidade">
                                Política de privacidade
                            </Link>
                            <Link to="/termos-de-uso">
                                Termos de uso
                            </Link>
                        </div>
                    </div>
                    <div className="button">
                        <Link to="/contato">
                            Entre em contato
                        </Link>
                        <Link to="/seja-nosso-representante">
                            Seja nosso representante
                        </Link>
                        <Link to="/trabalhe-conosco">
                            Trabalhe conosco
                        </Link>
                    </div>
                </div>
            </div>
            <div className="creditos">
                <div className="container">
                    <p>Alleanza Cerâmica | CNPJ.: {resumo.configuracoes.cnpj} | <a href={resumo.configuracoes.maps_rodape} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "normal" }}>{resumo.configuracoes.endereco}</a><br />{resumo.configuracoes.cidade} - {resumo.configuracoes.estado}</p>
                    <p> Desenvolvido por <a href="https://fw2propaganda.com.br" target="_blank" rel="noopener noreferrer">FW2 Propaganda ❤</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer;