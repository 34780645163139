import "./index.scss";
import { useState, useContext } from "react";

import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import Conta from "../../Assets/images/icons/conta.svg";
import Cart from "../../Assets/images/icons/cart.svg";
import Pesquisa from "../../Assets/images/icons/pesquisa.svg";
import { Link, useHistory } from "react-router-dom";

import Context from "../../Context";

const Header = () => {
    const history = useHistory();
    const { resumo, cart, loggedIn } = useContext(Context);
    const [menu, setMenu] = useState(false);
    const [pesquisa, setPesquisa] = useState(false);

    const handlePesquisa = (e) => {
        e.preventDefault();
        let formdata = new FormData(e.target);
        history.push(`/pesquisa/${formdata.get("pesquisa")}`);
        e.target.reset();
        setPesquisa(false);
    }

    return (
        <div id="header">
            <div className={`pesquisa ${pesquisa ? "active" : ""}`}>
                <div className="form">
                    <form onSubmit={handlePesquisa}>
                        <div className="inputs">
                            <label htmlFor="pesquisa">Pesquisar</label>
                            <input type="text" name="pesquisa" id="pesquisa" required />
                            <button type="submit" title="pesquisar"><img src={Pesquisa} alt="pesquisa" /></button>
                            <span onClick={() => setPesquisa(false)}>Voltar ao site</span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="primary">
                <div className="container">
                    <Link to="/">
                        <img src={resumo.imagens.logo} alt="Alleanza" />
                    </Link>
                    <button onClick={() => setMenu(!menu)}><img src={menu ? Close : Menu} alt="menu" /></button>
                    <div className={menu ? "active" : ""}>
                        <Link to="/" translate="no">
                            Home
                        </Link>
                        <Link to="/empresa" translate="no">
                            Alleanza
                        </Link>
                        <Link to="/produtos">
                            Produtos
                        </Link>
                        <Link to="/como-comprar">
                            Como comprar
                        </Link>
                        {/* <Link to="/showroom" translate="no">
                            Showroom
                        </Link> */}
                        <Link to="/blog">
                            Blog
                        </Link>
                        <span onClick={() => setPesquisa(true)}>
                            <img src={Pesquisa} alt="pesquisa" />
                        </span>
                        <Link to="/minha-conta">
                            <img src={Conta} alt="conta" />
                            Minha conta
                        </Link>
                        {loggedIn &&
                            <Link to="/carrinho">
                                <img src={Cart} alt="carrinho" />
                                {cart && cart.length > 0 && <span>{cart.length}</span>}
                            </Link>
                        }
                    </div>
                </div>
            </div>
            <div className="menu">
                <div className="container">
                    {resumo && resumo.colecoes.map((colecao, i) => {
                        if (i < 5) {
                            return (
                                <Link to={`/colecao/${colecao.slug}`} key={`colecao-header-list-${colecao.id}`}>
                                    {colecao.nome}
                                </Link>
                            )
                        } else {
                            return false;
                        }
                    }
                    )}
                    <Link to="/produtos">
                        Mais coleções
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default Header;